import * as React from "react";
import { navigate } from "gatsby";
import api from "../util/api";
import { Button, TextField, Box, Typography, CircularProgress, Alert, Avatar, InputAdornment, IconButton } from "@mui/material";

import { Popover } from "antd";

import Background from "/images/411440x810.jpeg";
import Logo from "/images/logo/iseelogo1.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import jwt_decode from "jwt-decode";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { validateRequire, validateRegex, validatePassword, validateEmail, validateMinCharacter, validateCompare } from "../util/validate";
import "./index.css";

export default function SignUp(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [translate, setTranslate] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [validate_field, setValidateField] = React.useState({
    user: [],
    pass: [],
    confirmpass: [],
    name: [],
    lastname: [],
    email: [],
    phone_number: [],
    department: [],
  });
  const [alert_err, setAlertErr] = React.useState("");
  const [onload, setOnLoad] = React.useState(false);
  const [user_data, setUserData] = React.useState({
    user: "",
    pass: "",
    confirmpass: "",
    name: "",
    lastname: "",
    email: "",
    phone_number: "",
    department: "",
  });
  const [showPass, setShowPass] = React.useState({
    pass: false,
    confirmpass: false,
  });

  let [validatePass, setValidatePass] = React.useState(validatePassword(user_data.pass));
  let [validateConfirmPass, setValidateConfirmPass] = React.useState(validatePassword(user_data.confirmpass));

  React.useEffect(() => {
    try {
      const url = new URL(window.location.href);
      const _token = url.searchParams.get("token");
      if (!_token) return null;
      var decoded = jwt_decode(_token);
      if (Date.now() > decoded.exp * 1000) {
        return setExpanded(true);
      } else {
        setUserData({
          ...user_data,
          name: decoded.profile.name,
          lastname: decoded.profile.lastname,
          email: decoded.profile.email,
          phone_number: decoded.profile.phone_number,
          department: decoded.profile.department,
        });
        setToken(_token);
      }
    } catch (err) {}
  }, []);

  const handleSubmit = () => {
    let check_validate = false;
    let register_validate = onValidate(user_data);
    for (let key in register_validate) {
      check_validate = register_validate[key].filter((item) => item !== null).length > 0;
      if (check_validate) break;
    }
    if (!check_validate) {
      setOnLoad(true);
      onSignup(user_data);
    }
  };

  const onValidate = (data) => {
    let reg_text_number = /^[0-9]+$/;

    validate_field.user = [];
    validate_field.user.push(
      validateRequire(data.user),
      validateMinCharacter(data.user, 3),
      validateRegex(data.user, /^[a-zA-Z0-9-_]+$/, "a - Z, 0 - 9")
    );

    validate_field.pass = [];
    validate_field.pass.push(
      validateRequire(data.pass),
      Object.keys(validatePassword(data.pass)).filter((x) => !validatePassword(data.pass)[x].isvalid).length > 0
        ? "เงื่อนไขการตั้งรหัสผ่านไม่ผ่าน"
        : null
    );

    validate_field.confirmpass = [];
    validate_field.confirmpass.push(
      validateRequire(data.confirmpass),
      validateCompare(data.pass, data.confirmpass, "รหัสผ่านไม่ตรงกัน"),
      Object.keys(validatePassword(data.confirmpass)).filter((x) => !validatePassword(data.confirmpass)[x].isvalid).length > 0
        ? "เงื่อนไขการตั้งรหัสผ่านไม่ผ่าน"
        : null
    );

    validate_field.name = [];
    validate_field.name.push(validateRequire(data.name));

    validate_field.lastname = [];
    validate_field.lastname.push(validateRequire(data.lastname));

    validate_field.email = [];
    validate_field.email.push(validateRequire(data.email), validateEmail(data.email));

    validate_field.phone_number = [];
    validate_field.phone_number.push(
      validateRequire(data.phone_number),
      validateRegex(data.phone_number, reg_text_number, "ตัวเลข 0 - 9 เท่านั้น ตัวอย่าง : 0815623548")
    );

    // validate_field.department = []
    // validate_field.department.push(
    //   validateRequire(data.department)
    // )

    setValidateField({ ...validate_field });

    return validate_field;
  };

  const onSignup = async (data) => {
    try {
      let data_regis = {
        user: data.user,
        pass: data.pass,
        application: "isee",
        profile: {
          name: data.name,
          lastname: data.lastname,
          email: data.email,
          phone_number: data.phone_number,
          department: data.department,
          facebook_line: "",
          system: "isee",
        },
        roles: [],
      };
      if (props.location.state) {
        if (props.location.state.profile.id) {
          data_regis.lineid = props.location.state.profile.id;
          data_regis.line_connect = true;
        }
      }
      const res = await api.registerByEmail(data_regis, token);
      setOnLoad(false);
      Swal.fire("", "ลงทะเบียนสำเร็จแล้ว", "success");
      navigate("/", { replace: true });
    } catch (err) {
      let text_alert = "";
      if (Array.isArray(err)) {
        err.forEach((ele, idx) => {
          idx === err.length - 1 ? (text_alert += ele) : (text_alert += ele + ", ");
        });
      } else {
        text_alert = err;
      }
      Swal.fire("", JSON.stringify(text_alert), "error");
      setOnLoad(false);
    }
  };

  if (expanded) {
    return (
      <div className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${Background})` }}>
        <div className="min-h-[100vh] flex justify-center xl:px-16 xl:pt-[100px] xl:pb-[100px]">
          <div className="w-full xl:w-1/3 rounded-3xl shadow-2xl backdrop-opacity-10 backdrop-invert bg-white/90">
            <Box className="flex flex-col items-center py-32 px-8 xl:py-4 xl:px-16">
              {props.location.state ? (
                <Avatar
                  className="my-4"
                  alt={props.location.state.profile.line.name}
                  src={props.location.state.profile.line.img}
                  sx={{ width: 150, height: 150 }}
                />
              ) : (
                <img src={Logo} alt="" style={{ width: 200 }} />
              )}

              <p className="text-xl">Link หมดอายุการใช้งาน กรุณาลงติดต่อผู้ดูแลระบบ</p>
              <a className="text-lg" onClick={() => navigate("/", { replace: true })}>
                กลับหน้าหลัก
              </a>
            </Box>
          </div>
        </div>
      </div>
    );
  }

  let contentPass = (
    <div>
      {Object.keys(validatePass).map((x) => {
        let _data = validatePass[x];
        if (_data.isvalid) {
          return <p className="line-through">- {_data.text}</p>;
        } else {
          return <p className="text-red-500">- {_data.text}</p>;
        }
      })}
    </div>
  );

  let contentConfirmPass = (
    <div>
      {Object.keys(validateConfirmPass).map((x) => {
        let _data = validateConfirmPass[x];
        if (_data.isvalid) {
          return <p className="line-through">- {_data.text}</p>;
        } else {
          return <p className="text-red-500">- {_data.text}</p>;
        }
      })}
    </div>
  );

  let cssTextInputBlack = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",
    },
  };

  return (
    <div className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${Background})` }}>
      <div className="min-h-[100vh] flex justify-center xl:px-16 xl:pt-[100px] xl:pb-[100px]">
        <div className="w-full xl:w-1/3 rounded-3xl shadow-2xl backdrop-opacity-10 backdrop-invert bg-white/90">
          <Box className="flex flex-col items-center py-32 px-8 xl:py-4 xl:px-16">
            {props.location.state ? (
              <Avatar
                className="my-4"
                alt={props.location.state.profile.line.name}
                src={props.location.state.profile.line.img}
                sx={{ width: 150, height: 150 }}
              />
            ) : (
              <img src={Logo} alt="" style={{ width: 200 }} />
            )}

            <Typography component="h1" variant="h5">
              {props.location.state ? `LINE : ${props.location.state.profile.line.name}` : translate?.ISEE_User_Registration}
            </Typography>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="grid grid-cols-4 gap-2">
                <TextField
                  className="col-span-4 md:col-span-2 bg-gray-200 not-allowed"
                  onChange={(e) => setUserData({ ...user_data, name: e.target.value })}
                  required
                  error={validate_field.name.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.name.filter((item) => item !== null).length > 0 ? validate_field.name.filter((item) => item !== null)[0] : ""
                  }
                  margin="normal"
                  id="firstname"
                  name="firstname"
                  label={"ชื่อ"}
                  disabled
                  value={user_data.name}
                  sx={cssTextInputBlack}
                  inputProps={{ maxLength: 50 }}
                />

                <TextField
                  className="col-span-4 md:col-span-2 bg-gray-200 not-allowed"
                  onChange={(e) => setUserData({ ...user_data, lastname: e.target.value })}
                  required
                  disabled
                  error={validate_field.lastname.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.lastname.filter((item) => item !== null).length > 0
                      ? validate_field.lastname.filter((item) => item !== null)[0]
                      : ""
                  }
                  margin="normal"
                  id="lastname"
                  name="lastname"
                  value={user_data.lastname}
                  label={"นามสกุล"}
                  sx={cssTextInputBlack}
                  inputProps={{ maxLength: 50 }}
                />

                <TextField
                  disabled
                  className="col-span-4 bg-gray-200"
                  onChange={(e) => setUserData({ ...user_data, email: e.target.value })}
                  required
                  error={validate_field.email.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.email.filter((item) => item !== null).length > 0 ? validate_field.email.filter((item) => item !== null)[0] : ""
                  }
                  margin="normal"
                  id="email"
                  name="email"
                  label="Email"
                  value={user_data.email}
                  sx={cssTextInputBlack}
                  inputProps={{ maxLength: 50 }}
                />

                <TextField
                  disabled
                  className="col-span-4 bg-gray-200"
                  onChange={(e) => setUserData({ ...user_data, department: e.target.value })}
                  required
                  error={validate_field.department.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.department.filter((item) => item !== null).length > 0
                      ? validate_field.department.filter((item) => item !== null)[0]
                      : ""
                  }
                  margin="normal"
                  id="department"
                  name="department"
                  value={user_data.department}
                  label={"หน่วยงาน"}
                  sx={cssTextInputBlack}
                  inputProps={{ maxLength: 50 }}
                />

                <TextField
                  className="col-span-4 md:col-span-2 bg-white"
                  onChange={(e) => setUserData({ ...user_data, user: e.target.value })}
                  required
                  error={validate_field.user.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.user.filter((item) => item !== null).length > 0 ? validate_field.user.filter((item) => item !== null)[0] : ""
                  }
                  margin="normal"
                  id="username"
                  name="username"
                  label="Username"
                  value={user_data.user}
                  inputProps={{ maxLength: 50 }}
                />

                <TextField
                  className="col-span-4 md:col-span-2 bg-white"
                  onChange={(e) => setUserData({ ...user_data, phone_number: e.target.value })}
                  required
                  error={validate_field.phone_number.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.phone_number.filter((item) => item !== null).length > 0
                      ? validate_field.phone_number.filter((item) => item !== null)[0]
                      : ""
                  }
                  margin="normal"
                  id="tel"
                  name="tel"
                  value={user_data.phone_number}
                  label={"เบอร์โทรศัพท์"}
                  inputProps={{ maxLength: 10 }}
                />

                <Popover placement="bottom" title="เงื่อนไขการตั้งรหัสผ่าน" content={contentPass} trigger="focus">
                  <TextField
                    className="col-span-4 md:col-span-2 bg-white"
                    onChange={(e) => {
                      setValidatePass({ ...validatePassword(e.target.value) });
                      setUserData({ ...user_data, pass: e.target.value });
                    }}
                    required
                    error={validate_field.pass.filter((item) => item !== null).length > 0}
                    helperText={
                      validate_field.pass.filter((item) => item !== null).length > 0 ? validate_field.pass.filter((item) => item !== null)[0] : ""
                    }
                    margin="normal"
                    id="password"
                    type={showPass.pass ? "text" : "password"}
                    name="password"
                    label="Password"
                    InputProps={{
                      maxLength: 50,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPass({ ...showPass, pass: !showPass.pass });
                            }}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPass.pass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Popover>

                <Popover placement="bottom" title="เงื่อนไขการตั้งรหัสผ่าน" content={contentConfirmPass} trigger="focus">
                  <TextField
                    className="col-span-4 md:col-span-2 bg-white"
                    onChange={(e) => {
                      setValidateConfirmPass({ ...validatePassword(e.target.value) });
                      setUserData({ ...user_data, confirmpass: e.target.value });
                    }}
                    required
                    error={validate_field.confirmpass.filter((item) => item !== null).length > 0}
                    helperText={
                      validate_field.confirmpass.filter((item) => item !== null).length > 0
                        ? validate_field.confirmpass.filter((item) => item !== null)[0]
                        : ""
                    }
                    margin="normal"
                    id="password"
                    type={showPass.confirmpass ? "text" : "password"}
                    name="password"
                    label="confirm password"
                    InputProps={{
                      maxLength: 50,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPass({ ...showPass, confirmpass: !showPass.confirmpass });
                            }}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPass.confirmpass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Popover>
              </div>
              {/* 
              {
                alert_err !== '' ?
                  <Alert variant="outlined" severity="error">
                    {alert_err}
                  </Alert>
                  : <></>
              } */}

              <Button
                disabled={onload}
                fullWidth
                sx={{ mt: 3, mb: 2, height: 48, fontSize: 18 }}
                onClick={handleSubmit}
                variant="contained"
                startIcon={onload ? <CircularProgress size={16} color="inherit" /> : false}
              >
                {onload ? "กำลังสร้างบัญชีผู้ใช้งาน" : "สร้างบัญชีผู้ใช้งาน"}
              </Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
